import { closeSlideoverOnMouseover, handleSlideoverChange } from '#core/utils/nav'
import { withTrailingSlash } from 'ufo'

export const topLevelMenu = computed(() => {
  const runtimeConfig = useRuntimeConfig()
  const auth = useAuth()
  return [
    [
      {
        icon: 'i-heroicons-home',
        to: auth.dashboardPath,
        label: 'Home & Playbook',
        on: closeSlideoverOnMouseover,
        shouldHighlight: (currentPath: string) => {
          return [
            auth.dashboardPath,
            'playbook-advanced',
            'token-settings',
          ].some((url: string) => currentPath.endsWith(url))
        },
      },
      {
        icon: 'i-forgd-tools',
        label: 'Tools & Services',
        key: 'tools-and-services',
        on: {
          mouseenter(event: MouseEvent) {
            handleSlideoverChange({
              event,
              slideover: 'tools-and-services',
            })
          },
        },
      },
      {
        icon: 'i-heroicons-academic-cap',
        label: 'Forgd Academy',
        key: 'forgd-academy',
        on: {
          mouseenter(event: MouseEvent) {
            handleSlideoverChange({
              event,
              slideover: 'forgd-academy',
            })
          },
        },
        shouldHighlight: (currentPath: string) => {
          return currentPath.startsWith('/academy')
        },
      },
      // TODO re-enable when ready
      // {
      //   label: 'Full-Service Advisory',
      //   to: 'https://www.forgd.com/solution/full-service-advisory',
      //   external: true,
      //   icon: 'i-heroicons-users',
      //   on: closeSlideoverOnMouseover,
      // },
      {
        label: 'Schedule a Consultation',
        to: runtimeConfig.public.consultationUrl,
        external: true,
        icon: 'i-heroicons-calendar-days',
        on: closeSlideoverOnMouseover,
      },
    ],
    [
      {
        icon: 'i-heroicons-information-circle',
        to: 'https://forgd.com/about',
        label: 'About Forgd',
        external: true,
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-chat-bubble-oval-left-ellipsis',
        to: '/support',
        label: 'Support',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-cog-8-tooth',
        to: '/settings',
        label: 'Account Settings',
        on: closeSlideoverOnMouseover,
      },
      {
        icon: 'i-heroicons-arrow-right-start-on-rectangle',
        label: 'Logout',
        loading: isLoggingOut.value,
        testId: 'logout',
        on: {
          click: logout,
          ...closeSlideoverOnMouseover,
        },
      },
    ],
  ] satisfies NavLink[][]
})

export function isCurrent({ to, shouldHighlight }: NavLink, strict: boolean = false) {
  const route = useRoute()
  if (to === undefined) {
    return false
  }
  // we just care about the first path segment matching
  const pathSegments = (to || '').split('/').filter(Boolean)
  const currPathSegments = (route.path || '').split('/').filter(Boolean)
  if (!strict) {
    if (withTrailingSlash(currPathSegments[0]) === withTrailingSlash(pathSegments[0])) {
      return true
    }

    if (shouldHighlight && shouldHighlight(route.path)) {
      return true
    }
  }
  // special logic, we match the top-level category which is the first 3 segments
  if (route.path.startsWith('/academy')) {
    const categoryPath = currPathSegments.slice(0, 3).join('/')
    return withTrailingSlash(categoryPath) === withTrailingSlash(pathSegments.slice(0, 3).join('/'))
  }
  // requires full match
  return withTrailingSlash(to) === withTrailingSlash(route.path)
}

const TokenDesigner: NavLink = { label: 'Token Designer', to: '/token-designer', icon: '/icons/token-designer-next.svg', coreTool: true }
const TokenUnlockMonitoring: NavLink = { label: 'Token Unlock Monitoring', to: '/token-unlocks', icon: '/icons/token-unlock-monitoring.svg', coreTool: true }
const EngageAMarketMaker: NavLink = { label: 'Engage a Market Maker', to: '/engage-a-market-maker', icon: '/icons/engage-a-market-maker.svg', coreTool: true }
// TODO re-enable when ready
// const MarketMakerLeaderboards: NavLink = { label: 'Market Maker Leaderboards', to: '/market-maker-leaderboards', icon: '/icons/market-maker-leaderboards.svg', coreTool: true }
const ExchangeListingResearch: NavLink = { label: 'Exchange Listing Research', to: '/exchange-listing', icon: '/icons/exchange-listing-research.svg', coreTool: true }
const MarketMakerMonitoring: NavLink = { label: 'Market Maker Monitoring', to: '/market-maker-monitoring', icon: '/icons/market-maker-monitoring-next.svg', coreTool: true }
const LiquidityLaunchDesigner: NavLink = { label: 'Liquidity & Launch Designer', to: '/liquidity-launch-designer', icon: '/icons/liquidity-launch-designer.svg', coreTool: true }

export const coreToolsPreTge: NavLink[] = [
  TokenDesigner,
  EngageAMarketMaker,
  // TODO re-enable when ready
  // MarketMakerLeaderboards,
  ExchangeListingResearch,
]

// TODO: should be removed when the feature will be finished.
export const coreToolsPreTgeWithLaunchDesigner: NavLink[] = [
  ...coreToolsPreTge,
  LiquidityLaunchDesigner,
]

export const coreToolsListed: NavLink[] = [
  MarketMakerMonitoring,
  TokenUnlockMonitoring,
  EngageAMarketMaker,
  // TODO re-enable when ready
  // MarketMakerLeaderboards,
  ExchangeListingResearch,
]

export const coreTools: NavLink[] = [
  TokenDesigner,
  TokenUnlockMonitoring,
  EngageAMarketMaker,
  MarketMakerMonitoring,
  // TODO re-enable when ready
  // MarketMakerLeaderboards,
  ExchangeListingResearch,
]

export const preTgeNav: NavLink[] = [
  {
    label: 'Tokenomics & Protocol',
    icon: 'i-heroicons-cube',
    children: [
      TokenDesigner,
      { label: 'Token Unlocks', to: '/token-unlocks' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/pre-tge-smart-contract-protocol' },
      { label: 'Token Creation & Mint', to: '/partner-schedule/magna' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
      { label: 'Multi-Sig Token Custody', to: '/partner-schedule/squads' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/13TsO0y64pvv1mAbParQjK7AIIK0FxICc3CtBIQd3NTk/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
      { label: 'Go-to-Market Budgeting', to: 'https://docs.google.com/spreadsheets/d/1P0RhK4QYrTt9cqfCpf46qV1FnpH82N7CtlYkei3ISgU/edit?gid=1931257942#gid=1931257942' },
      { label: 'Public Sale, Launchpad', to: '/partner-schedule/legion' },
    ],
  },
  {
    label: 'Go-to-Market Partnership',
    icon: 'i-heroicons-globe-alt',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      EngageAMarketMaker,
      ExchangeListingResearch,
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
      { label: 'Launch Checklist', to: 'https://docs.google.com/spreadsheets/d/1zo0zxfqXzvivroHi38jO0wHXLvpmRPO1dc6BRMMsB14/edit?usp=sharing' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'Entity formation & incorporation, External General Counsel, & Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]

export const listedTokenNav: NavLink[] = [
  {
    label: 'Analysis & Insights',
    icon: 'i-heroicons-light-bulb',
    children: [
      { label: 'Market Maker Monitoring', to: '/market-maker-monitoring' },
      TokenUnlockMonitoring,
      { label: 'Volume, Liquidity & Price Performance', to: '/partner-activity-lead/volume-liquidity-price-performance' },
    ],
  },
  {
    label: 'Performance Optimization',
    icon: 'i-heroicons-chart-bar-square',
    children: [
      { label: 'Tokenomics & Demand Drivers', to: '/partner-activity-lead/tokenomics-demand-drivers' },
      // { label: 'Demand Driver Optimization', to: '/token-designer' },
      { label: 'AMM²', to: '/partner-activity-lead/amm-2' },
      { label: 'Smart Contract Development', to: '/partner-activity-lead/post-tge-smart-contract-protocol' },
      { label: 'Airdrop & Token Streaming', to: '/partner-schedule/magna' },
    ],
  },
  {
    label: 'Strategic Partnership',
    icon: 'i-heroicons-puzzle-piece',
    children: [
      { label: 'Listing & Liquidity Academy', to: 'https://docsend.com/view/fexpeu4nxmvt8j72' },
      EngageAMarketMaker,
      ExchangeListingResearch,
      { label: 'Marketing & KOL Support', to: 'https://docs.google.com/spreadsheets/d/1k7pyJe5x24wBnMET428rNqMTaKvPlsMqE1lZlLqeoC0/edit?gid=1326628848#gid=1326628848' },
    ],
  },
  {
    label: 'Finance and Fundraising',
    icon: 'i-heroicons-banknotes',
    children: [
      { label: 'Cashflow Analysis', to: 'https://docs.google.com/spreadsheets/d/1xElL1SBH88T1xyxTCpne2qPfJy7vouoDp2UZOzsO18A/edit?gid=798759478#gid=798759478' },
      { label: 'Growth Capital', to: 'https://docs.google.com/spreadsheets/d/1FWjsPxQALgSIOeXEmuvbRqb-PiWhZtWCvjGyQXegTFY/edit?gid=147303761#gid=147303761' },
    ],
  },
  {
    label: 'Legal & Compliance',
    icon: 'i-heroicons-scale',
    children: [
      { label: 'External General Counsel, & Legal Opinion on Token', to: '/partner-schedule/cavenwell' },
    ],
  },
]
